<template>
  <div>
    <v-form @submit.prevent="onBeforeSubmit" ref="formRef">
      <v-row dense class="pt-4 pb-0 mb-0">
        <div class="center-text h1"> Procedimento: {{ currentItem.procedimento.procedimento }} {{ currentItem.procedimento.nomecomercial ? ` - "${currentItem.procedimento.nomecomercial}" - Cód.: ${currentItem.procedimento.codigo} ` : (`- Cód.: ${currentItem.procedimento.codigo}`) }}</div>
      </v-row>
      <v-row dense class="pt-4 mt-4">
        <v-col>
          <v-text-field label="Valor do Procedimento" outlined v-model.lazy="currentItem.valor_cobranca"
            v-money="currencyMask">
            <template v-slot:prepend-inner>
              <span style="position: relative; top: 2.5px">R$</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Desconto" outlined v-model.lazy="currentItem.desconto" v-money="currencyMask">
            <template v-slot:append>%</template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Acréscimo" outlined v-model.lazy="currentItem.acrescimo" v-money="currencyMask">
            <template v-slot:append>%</template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Total" outlined v-model="procedureTotalValue" disabled>
            <template v-slot:prepend-inner>
              <span style="position: relative; top: 2.5px">R$</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-end">
          <ConfirmButton color="success" :loading="loading">
            Salvar
          </ConfirmButton>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
  
<script>
import ConfirmButton from '../../ConfirmButton.vue';
import UtilsFunc from '../../../service/utilsFunc';
import '../../../assets/css/styles.css';

const { currencyToNumber, numberToCurrency } = UtilsFunc
export default {
  name: 'ProcedurePriceForm',
  props: {
    onSubmit: Function,
    currentItem: Object,
    procedures: Array,
    frequencies: Array,
    statusOptions: Array,
    loading: Boolean
  },
  data: () => UtilsFunc.withCRUDUtils({
    oldUnitPrice: null,
    currencyMask: {
      decimal: ',',
      thousands: '.',
      precision: 2,
    },
  }),
  methods: {
    formatNumber(value) {
      if (typeof value === 'string') {
        value = value.replace(',', '.');
      }
      return parseFloat(value) || 0;
    },
    onBeforeSubmit() {
      const validation = this.$refs.formRef.validate()

      if (!validation) return
      const proceduresToUpdate = this.procedures.filter(procedure => {

        const isSameProcedure = procedure.procedimento.procedimento_id === this.currentItem.procedimento.procedimento_id
        const isSameFrequency = procedure.frequencia.nome === this.currentItem.frequencia.nome
        const isSameCharge = procedure.cobranca === this.currentItem.cobranca
        const isSamePrice = procedure.valor_cobranca === this.oldUnitPrice
        const isSameBudget = procedure.aditivo === this.currentItem.aditivo

        return isSameProcedure && isSameCharge && isSameFrequency && isSamePrice && isSameBudget
      })

      const valorCobrancaFinal = this.formatNumber(this.procedureTotalValue);

      const formatField = (procedure) => { 
        return {
          cobranca: Boolean(procedure.cobranca),
          pagamento: Boolean(procedure.pagamento),
          sessao: Number(procedure.guia),
          frequencia: procedure.frequencia.id,
          valor_cobranca: currencyToNumber(this.currentItem.valor_cobranca),
          valor_cobranca_final: valorCobrancaFinal,
          procedimento: this.currentItem.procedimento.procedimento_id,
          acrescimo: currencyToNumber(this.currentItem.acrescimo),
          desconto: currencyToNumber(this.currentItem.desconto),
          id: procedure.id
        }
      }

      const formattedItems = proceduresToUpdate.map(formatField)
      this.onSubmit(formattedItems)
    },
    formatTimestamp() {
      if (this.currentItem.timestamp) {
        const date = new Date(this.currentItem.timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const weekDay = date.toLocaleDateString('pt-BR', { weekday: 'long' });

        return `${day}/${month}/${year} - ${weekDay.charAt(0).toUpperCase() + weekDay.slice(1)}`;
      }
      return '';
    },
  },
  components: { ConfirmButton },
  computed: {
    isTimestampValid() {
      // Retorna verdadeiro se `timestamp` existe e é uma string não-vazia.
      return this.currentItem.timestamp && typeof this.currentItem.timestamp === 'string';
    },
    procedureTotalValue() {
      const addition = currencyToNumber(this.currentItem.acrescimo) || 0
      const discount = currencyToNumber(this.currentItem.desconto) || 0
      const baseValue = currencyToNumber(this.currentItem.valor_cobranca)
      const result =
        baseValue + (baseValue * (addition / 100)) - (baseValue * (discount / 100))
      return numberToCurrency(result)
    }
  },
  beforeMount() {
    this.oldUnitPrice = this.currentItem.valor_cobranca
    this.currentItem.valor_cobranca = numberToCurrency(this.currentItem.valor_cobranca)
    this.currentItem.acrescimo = numberToCurrency(this.currentItem.acrescimo)
    this.currentItem.desconto = numberToCurrency(this.currentItem.desconto)
  }
}
</script>
  
<style scoped>
.center-text {
  text-align: center;
  font-size: 20px;
}
</style>
